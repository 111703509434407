import React, { useEffect, useRef } from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import axios from 'axios';
import { Box, Typography, Toast } from '../elements';
import { t } from '../../../js/common/translations';
import '@adyen/adyen-web/dist/adyen.css';
import { useToggle } from '../../hooks/useToggle';
import { getElement } from '../../../js/common/asyncElementGetter';

const AdyenPaymentComponent = ({
  adyenClientKey,
  adyenEnvironment,
  adyenActionPath,
  adyenSessionInfo = null,
  adyenCardResponse,
  adyenSaveLogPath = null,
  onPaymentSucceeded,
  adyenSuccessCallbackPath,
  paymentLink,
  paymentType = null,
  reservationFormPath = null
}) => {
  const [showErrorToast, setShowErrorToast] = useToggle();
  const paymentContainer = useRef(null);
  const formTranslations = {
    en: {
      payButton: t('adyen_component.pay_button')
    },
    pt: {
      payButton: t('adyen_component.pay_button')
    }
  };

  const handleOnError = async(error, component) => {
    try {
      await axios.post(adyenSaveLogPath, {
        error_name: error.name,
        error_message: error.message,
        error_stack: error.stack,
        error_cause: error.cause,
        description: paymentLink.description
      });

      setShowErrorToast(true);
    } catch (e) {
      setShowErrorToast(true);
    }
  };

  const handleSubmit = async(state, dropin) => {
    dropin.setStatus('loading');

    try {
      const response = await axios.post(adyenActionPath, { payload: state, payment_link: paymentLink });
      // eslint-disable-next-line camelcase
      const reservationFormPath = await response?.data?.reservation_form_path;

      if (reservationFormPath) {
        window.location.href = reservationFormPath;
        return;
      }

      if (adyenSuccessCallbackPath) await axios.get(adyenSuccessCallbackPath);
      onPaymentSucceeded();
    } catch (e) {
      console.error(e);
      setShowErrorToast(true);
    }

    dropin.setStatus('ready');
  };

  const handleOnPaymentCompleted = async(result, component) => {
    try {
      const sessionResponse = { ...result, description: paymentLink.description };
      const { data: response } = await axios.post(adyenActionPath, { sessionResponse: sessionResponse });

      if (response.success) {
        if (reservationFormPath) {
          window.location = reservationFormPath;
          return;
        }

        onPaymentSucceeded();
      } else {
        setShowErrorToast(true);
      }
    } catch (e) {
      console.error(e);
      setShowErrorToast(true);
    }
  };

  const paymentMethodsFromResponse = () => {
    const { paymentMethods: sourcePaymentMethods } = paymentLink ? adyenCardResponse : JSON.parse(atob(adyenCardResponse));

    if (paymentType === 'pix') {
      return sourcePaymentMethods.filter(({ type }) => type === 'pix');
    }

    return sourcePaymentMethods.filter(({ type }) => type !== 'pix');
  };

  const locale = document.querySelector('html').getAttribute('lang');

  const sessionFlowconfig = (id, sessionData) => {
    return {
      paymentMethodsResponse: { paymentMethods: paymentMethodsFromResponse() },
      environment: adyenEnvironment,
      clientKey: adyenClientKey,
      locale: locale,
      translations: formTranslations,
      analytics: {
        enabled: true
      },
      session: {
        id: id, // Unique identifier for the payment session.
        sessionData: sessionData // The payment session data.
      },
      onPaymentCompleted: handleOnPaymentCompleted,
      onError: handleOnError,
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          enableStoreDetails: true,
          showBrandsUnderCardNumber: false,
          hideCVC: false,
          name: t('adyen_component.card_name')
        },
        pix: {
          name: t('adyen_component.pix_name'),
          personalDetailsRequired: true
        }
      }
    };
  };

  const paymentFlowConfig = {
    paymentMethodsResponse: { paymentMethods: paymentMethodsFromResponse() },
    clientKey: adyenClientKey,
    locale: locale,
    translations: formTranslations,
    environment: adyenEnvironment,
    onSubmit: handleSubmit,
    paymentMethodsConfiguration: {
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        enableStoreDetails: true,
        showBrandsUnderCardNumber: false,
        hideCVC: false,
        name: t('adyen_component.card_name')
      }
    }
  };

  useEffect(() => {
    const onReady = async() => {
      if (paymentType !== 'pix') {
        const adyenCheckbox = await getElement('.adyen-checkout__checkbox', false, false);
        const adyenCheckboxInput = adyenCheckbox?.querySelector('input');
        if (adyenCheckboxInput?.value === 'false') { adyenCheckbox.click() }
        const adyenCheckoutForm = await getElement('.adyen-checkout__dropin', false, false);
        adyenCheckoutForm.classList.add('save-card-details');
        const adyenSubmitButton = await getElement('.adyen-checkout__button', false, false);
        adyenSubmitButton.classList.add('save-card-bt');
      }
    };

    const mountAdyenCheckout = async() => {
      if (!adyenSessionInfo) {
        const checkout = await AdyenCheckout(paymentFlowConfig);
        checkout.create('dropin', { onReady }).mount(paymentContainer.current);
      } else {
        const { id, sessionData } = adyenSessionInfo;

        const checkout = await AdyenCheckout(sessionFlowconfig(id, sessionData));
        checkout.create('dropin', { onReady }).mount(paymentContainer.current);
      }
    };

    mountAdyenCheckout();
  }, []);

  return (
    <Box m={[2, 4, 6]} mb={[6]} mt={[2, 4, 12.5]}>
      {adyenEnvironment === 'test' &&
        <Typography textAlign="center" sx={{ bgcolor: 'warning.main' }} >
          TEST MODE
        </Typography>
      }
      <div ref={paymentContainer} />
      <Box mt={1}>
        <Typography color="primary.70" variant="lead" sx={{ display: paymentType === 'pix' && 'none' }}>
          {t('checkout_success_page.card_save_msg')}
        </Typography>
      </Box>
      <Toast
        type="error"
        text={t('checkout_success_page.error.message')}
        open={showErrorToast}
      />
    </Box>
  );
};

export default AdyenPaymentComponent;
