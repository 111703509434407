import React, { useState, useEffect } from 'react';
import {
  Box,
  SvgIcon,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button
} from '../../../elements';
import {
  UploadFileInput,
  InputGroup,
  FormTitle,
  UploadBox,
  BottomButtonsWrapper,
  Container
} from '../ReservationForm.styles';
import { t } from '../../../../../js/common/translations';
import { ThemeProvider } from '../../../../contexts/theme';
import Dropzone from 'react-dropzone';
import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import inputValidator from '../../../../utils/inputValidator';
import countryList from 'react-select-country-list';
import { DesktopStepper, FinishLaterModal, MobileStepper, TopStepper } from '../ReservationFormComponents';
import axios from 'axios';

const reasonsBookings = [
  {
    label: t('reservation_form.nationality.reasons.medical'),
    value: 'medical'
  },
  {
    label: t('reservation_form.nationality.reasons.residence_relocation'),
    value: 'residence-relocation'
  },
  {
    label: t('reservation_form.nationality.reasons.work'),
    value: 'work'
  },
  {
    label: t('reservation_form.nationality.reasons.renovation'),
    value: 'renovation'
  },
  {
    label: t('reservation_form.nationality.reasons.study'),
    value: 'study'
  },
  {
    label: t('reservation_form.nationality.reasons.leisure'),
    value: 'leisure'
  },
  {
    label: t('reservation_form.nationality.reasons.special_event'),
    value: 'special-event'
  },
  {
    label: t('reservation_form.nationality.reasons.remote_work'),
    value: 'remote-work'
  },
  {
    label: t('reservation_form.nationality.reasons.other'),
    value: 'other'
  }

];

const PhotoPreview = ({ src, deletePhoto, index, fileName, setPreviewUrl }) => {
  const handlePhotoDelete = (index) => {
    setPreviewUrl('');
    deletePhoto(index);
  };

  return (
    <Box sx={{ position: 'relative' }} display="flex" justifyContent='space-between' width="100%">
      <Box display="flex" alignItems="center">
        <Box
          component='img'
          src={src}
          style={{
            width: '74px'
          }}
        />
        <Typography ml={0.5}>{fileName}</Typography>
      </Box>
      <SvgIcon
        onClick={() => handlePhotoDelete(index)}
        width={40}
        name="close_icon"
        height={40}
        sx={{ top: '3px', right: '3px' }}
      />
    </Box>
  );
};

const INITIAL_NATIONALITY_OBJ = {
  nationality: '',
  id_number: '',
  reason: '',
  reason_other: ''
};

const Nationality = ({
  reservationInfo = INITIAL_NATIONALITY_OBJ,
  previousStepUrl,
  nextStepUrl,
  reservationFormSubmitPath,
  formStatus,
  simplifiedForm,
  simplifiedFormWithoutAddress
}) => {
  const mobile = ['xs', 'sm'].includes(useBreakpoint());
  const [openFinishLater, setOpenFinishLater] = useState(false);
  const isSubmitted = formStatus === 'Submitted' || formStatus === 'Approved';
  const options = countryList().getData();
  const [cpfError, setCpfError] = useState('');
  const [selfieError, setSelfieError] = useState('');
  const [loading, setLoading] = useState(false);
  const [documentError, setDocumentError] = useState('');
  const [errorsText, setErrorsText] = useState([]);
  const [nationalityInfo, setNationalityInfo] = useState({
    nationality: reservationInfo.nationality,
    id_number: reservationInfo.id_number,
    reason: reservationInfo.reason,
    reason_other: reservationInfo.reason_other === false || reservationInfo.reason_other === null ? '' : reservationInfo.reason_other
  });

  const [guestInfo, setGuestInfo] = useState({
    nationality: reservationInfo.guests[0].guest.nationality,
    id_number: reservationInfo.guests[0].guest.id_number
  });

  const [images, setImages] = useState([
    {
      file: '',
      title: 'guest_selfie'
    },
    {
      file: '',
      title: 'guest_document'
    }
  ]);

  const [selfieUrl, setSelfieUrl] = useState(reservationInfo.guests[0].selfie_photo || '');
  const [documentUrl, setDocumentUrl] = useState(reservationInfo.guests[0].document_photo || '');

  useEffect(() => {
    let url = nextStepUrl.split('?')[0];

    if (simplifiedFormWithoutAddress) {
      url += '?step=5';
    } else {
      url += '?step=6';
    }

    if (isSubmitted) {
      window.location.href = url;
    }
  }, [formStatus]);

  const formValidations = [
    ['nationality', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['id_number', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['reason', { validation: 'required', errorMessage: t('form_errors.empty_field') }]
  ];

  const updateFormOwner = (inputName) => ({ target: { value } }) => {
    setNationalityInfo(state => ({
      ...state,
      [inputName]: value
    }));

    setGuestInfo(state => ({
      ...state,
      [inputName]: value
    }));
  };

  const reservationFormData = (information, step) => {
    const formData = new FormData();

    Object.entries(information).forEach((value) => {
      formData.append(`reservation_form[${value[0]}]`, value[1]);
    });

    formData.append('reservation_form[status]', 1);
    formData.append('reservation_form[form_step]', step);

    return formData;
  };

  const guestData = (guest, images) => {
    const formData = new FormData();

    Object.entries(guest).forEach((value) => {
      formData.append(`reservation_form_guest[${value[0]}]`, value[1]);
    });

    images.forEach((image) => {
      formData.append('images[files][][file]', image.file);
      formData.append('images[files][][title]', image.title);
    });

    return formData;
  };

  const handleSubmitForm = async(url, step = 3) => {
    const reservationForm = reservationFormData(nationalityInfo, step);
    const reservationFormGuest = guestData(guestInfo, images);
    setLoading(true);
    const errors = inputValidator(nationalityInfo, formValidations);
    const reservationFormGuestPath = `${window.location.origin}/reservation_form/${reservationInfo.id}/reservation_form_guests/${reservationInfo.guests[0].guest_id}`;
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    const isSimplifiedForm = () => {
      if (!simplifiedForm) {
        return selfieUrl === '' || documentUrl === '';
      }
    };

    if (
      (errors.length ||
      (images[0].file.size / 1024) / 1024 > 15 ||
      (images[1].file.size / 1024) / 1024 > 15 ||
      (nationalityInfo.nationality === 'Brazil' && nationalityInfo.id_number.length < 11)) || isSimplifiedForm()
    ) {
      setErrorsText(errors);
      setLoading(false);

      if (nationalityInfo.nationality === 'Brazil' && nationalityInfo.id_number.length < 11) {
        setCpfError(t('reservation_form.guests.cpf_error'));
      }
      if (!simplifiedForm && selfieUrl === '') {
        setSelfieError(t('reservation_form.nationality.upload_required_text'));
      }

      if (!simplifiedForm && documentUrl === '') {
        setDocumentError(t('reservation_form.nationality.upload_required_text'));
      }

      if ((images[0].file.size / 1024) / 1024 > 15) {
        setSelfieError(t('reservation_form.nationality.rne_passport_upload_subtitle'));
      }

      if ((images[1].file.size / 1024) / 1024 > 15) {
        setDocumentError(t('reservation_form.nationality.rne_passport_upload_subtitle'));
      }
    } else {
      try {
        await axios.patch(reservationFormSubmitPath, reservationForm);
        await axios.patch(reservationFormGuestPath, reservationFormGuest, config);
        window.location.href = url;
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleChange = (inputName) => ({ target: { value } }) => {
    setNationalityInfo(state => ({
      ...state,
      [inputName]: value
    }));
  };

  const getErrorMessage = (inputName) => {
    return errorsText.filter(el => el.field === inputName)[0]?.error;
  };

  const handleImageChange = (index, title) => (files) => {
    setImages(state => {
      const file1Copy = { ...state[0] };
      const file2Copy = { ...state[1] };

      const stateCopy = [file1Copy, file2Copy];

      stateCopy[index] = {
        file: files[0],
        title: title
      };

      return stateCopy;
    });
  };

  const deleteImage = (index) => {
    setImages(state => {
      const file1Copy = { ...state[0] };
      const file2Copy = { ...state[1] };

      const stateCopy = [file1Copy, file2Copy];

      stateCopy[index] = {
        ...stateCopy[index],
        file: ''
      };

      return stateCopy;
    });
  };

  const getFileName = (file, title) => {
    if (typeof file === 'object') {
      return file.path;
    }
    return title;
  };

  useEffect(() => {
    if (images[0].file) setSelfieUrl(URL.createObjectURL(images[0].file));
    if (images[1].file) setDocumentUrl(URL.createObjectURL(images[1].file));
  }, [images]);

  const maskCPF = (value = '') => {
    if (typeof value !== 'string') return;

    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  useEffect(() => {
    if (nationalityInfo.nationality === 'Brazil') {
      setGuestInfo((state) => ({ ...state, id_number: maskCPF(nationalityInfo.id_number) }));
      setNationalityInfo((state) => ({ ...state, id_number: maskCPF(nationalityInfo.id_number) }));
    }
  }, [nationalityInfo.nationality]);

  return (
    <ThemeProvider>
      <Container>
        <Box
          px={{ xs: 1, md: 2 }}
          pb={6}
          pt={1}
          height={{ xs: 'auto', md: '100vh' }}
          sx={{ overflowY: 'auto' }}
        >
          <Box width="100%" display="flex" justifyContent="center" pt={{ xs: 1, md: 5 }} pb={{ xs: 2, md: 5 }}>
            <TopStepper />
          </Box>
          <Box>
            {mobile ? (
              <MobileStepper
                porcentageValue={20}
                actualStep={2}
                title={t('reservation_form.nationality.nationality')}
                hideAddress={simplifiedFormWithoutAddress}
              />
            ) : (
              <DesktopStepper activeStep={1} pb={2} path={nextStepUrl} hideAddress={simplifiedFormWithoutAddress} />
            )}
          </Box>
          <Box mt={{ xs: 2, md: 4 }}>
            <FormTitle mb={0.5}>{t('reservation_form.nationality.title')}</FormTitle>
            <InputGroup>
              <FormControl sx={{ width: '100%' }} >
                <InputLabel id="nationality-label">
                  {t('reservation_form.nationality.nationality')}
                </InputLabel>
                <Select
                  labelId='nationality-label'
                  margin="dense"
                  label={t('reservation_form.nationality.nationality')}
                  disabled={isSubmitted}
                  value={nationalityInfo.nationality}
                  onChange={updateFormOwner('nationality')}
                  error={!!getErrorMessage('nationality')}
                  helperText={getErrorMessage('nationality')}
                  sx={{
                    mr: { xs: 0, md: 1 },
                    mt: 0
                  }}
                >
                  {options.map(({ label, value }) => (
                    <MenuItem key={value} value={label}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box width="100%">
                <TextField
                  onChange={updateFormOwner('id_number')}
                  value={nationalityInfo.nationality === 'Brazil' ? maskCPF(nationalityInfo.id_number) : nationalityInfo.id_number}
                  disabled={isSubmitted}
                  label={t(`reservation_form.nationality.${nationalityInfo.nationality === 'Brazil' ? 'cpf' : 'document_number'}`)}
                  maxLength={nationalityInfo.nationality === 'Brazil' && 8}
                  error={!!getErrorMessage('id_number') || !!cpfError}
                  helperText={getErrorMessage('id_number')}
                  margin="dense"
                  sx={{
                    width: '100%',
                    ml: 0,
                    mt: { xs: 0.5, md: 0 },
                    '& p': { display: getErrorMessage('id_number') ? 'block' : 'none' }
                  }}
                />
                {!!cpfError && (
                  <Box display="flex" mt={0.25}>
                    <SvgIcon name="error_input_icon" />
                    <Typography fontSize={12} ml={0.25} color="red">{cpfError}</Typography>
                  </Box>
                )}
              </Box>
            </InputGroup>
            <InputGroup>
              <FormControl sx={{ width: { xs: '100%', md: 218 }, mt: 0.5 }}>
                <InputLabel id="reasons">{t('reservation_form.nationality.reasons_booking')}</InputLabel>
                <Select
                  value={nationalityInfo.reason}
                  onChange={handleChange('reason')}
                  disabled={isSubmitted}
                  error={!!getErrorMessage('reason')}
                  helperText={getErrorMessage('reason')}
                  label={t('reservation_form.nationality.reasons_booking')}
                  labelId="reasons"
                >
                  {reasonsBookings.map(({ label, value }, i) => (
                    <MenuItem value={value} key={i}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </InputGroup>
            {nationalityInfo.reason === 'other' && (
              <InputGroup mt={0.5}>
                <TextField
                  label={t('reservation_form.nationality.other')}
                  margin="dense"
                  disabled={isSubmitted}
                  value={nationalityInfo.reason_other || ''}
                  onChange={handleChange('reason_other')}
                  multiline
                  maxRows={4}
                  sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                      height: '110px'
                    }
                  }}
                />
              </InputGroup>
            )}
            <InputGroup simplifiedForm={simplifiedForm} column>
              <FormTitle mt={0.5}>{t(`reservation_form.nationality.rne_passport_upload_title${nationalityInfo.nationality === 'Brazil' ? '_brazil' : ''}`)}</FormTitle>
              <Typography
                fontSize='0.75rem'
                sx={{ color: 'primary.70' }}
                mb={0.5}
              >
                {t('reservation_form.nationality.rne_passport_upload_subtitle')}
              </Typography>
              <Dropzone
                accept={{ 'image/png': ['.png', '.jpg', '.jpeg'] }}
                disabled={!!documentUrl}
                onDrop={handleImageChange(1, 'guest_document')}
                style={{ dropzoneActive: { opacity: 0.8 } }}
              >
                {({ getRootProps, getInputProps }) => (
                  <>
                    <UploadFileInput sx={{ width: '100%' }} htmlFor="file-upload">
                      <UploadBox
                        required={documentError}
                        {...getRootProps({
                          className: 'dropzone'
                        })}>
                        {documentUrl ? (
                          <>
                            <SvgIcon name="success_large" width={74} height={74} />
                            <Typography>{t('reservation_form.file_upload_success')}</Typography>
                          </>
                        ) : (
                          <>
                            <SvgIcon name="file_upload" width={74} height={74} />
                            <Typography
                              fontSize='0.75rem'
                              sx={{ color: 'primary.70' }}
                              mb={0.5}
                            >
                              {t('reservation_form.nationality.upload_field_text')}
                            </Typography>
                          </>
                        )}
                      </UploadBox>
                      <input
                        id="file-upload"
                        type="file"
                        margin="dense"
                        disabled={!!documentUrl}
                        {...getInputProps()}
                        sx={{ display: 'none' }}
                      />
                    </UploadFileInput>
                    {documentError && (
                      <Box display="flex" mt={0.25}>
                        <SvgIcon name="error_input_icon" />
                        <Typography fontSize={12} ml={0.25} color="red">{documentError}</Typography>
                      </Box>
                    )}
                  </>
                )}
              </Dropzone>
              <Box display='flex' alignItems='center' pt={1}>
                {documentUrl &&
                  <PhotoPreview
                    src={documentUrl}
                    deletePhoto={deleteImage}
                    index={1}
                    fileName={getFileName(images[1].file, 'guest_document')}
                    setPreviewUrl={setDocumentUrl}
                  />
                }
              </Box>
            </InputGroup>
            <InputGroup simplifiedForm={simplifiedForm} column>
              <FormTitle mt={0.5}>{t('reservation_form.nationality.selfie_title')}</FormTitle>
              <Typography fontSize='0.75rem' mb={0.5} sx={{ color: 'primary.70' }}>{t('reservation_form.nationality.selfie_subtitle')}</Typography>
              <Dropzone
                accept={{ 'image/png': ['.png', '.jpg', '.jpeg'] }}
                disabled={!!selfieUrl}
                onDrop={handleImageChange(0, 'guest_selfie')}
                style={{ dropzoneActive: { opacity: 0.8 } }}
              >
                {({ getRootProps, getInputProps }) => (
                  <>
                    <UploadFileInput sx={{ width: '100%' }} htmlFor="file-upload2">
                      <UploadBox
                        required={selfieError}
                        {...getRootProps({
                          className: 'dropzone'
                        })}>
                        {selfieUrl !== '' ? (
                          <>
                            <SvgIcon name="success_large" width={74} height={74} />
                            <Typography>{t('reservation_form.file_upload_success')}</Typography>
                          </>
                        ) : (
                          <>
                            <SvgIcon name="file_upload" width={74} height={74} />
                            <Typography fontSize='0.75rem' mb={0.5} sx={{ color: 'primary.70' }}>
                              {t('reservation_form.nationality.upload_field_text')}
                            </Typography>
                          </>
                        )}
                      </UploadBox>
                      <input
                        id="file-upload2"
                        type="file"
                        margin="dense"
                        disabled={!!selfieUrl}
                        {...getInputProps()}
                        sx={{ display: 'none' }}
                      />
                    </UploadFileInput>
                    {selfieError && (
                      <Box display="flex" mt={0.25}>
                        <SvgIcon name="error_input_icon" />
                        <Typography fontSize={12} ml={0.25} color="red">{selfieError}</Typography>
                      </Box>
                    )}
                  </>
                )}
              </Dropzone>
              <Box sx={{ my: 1 }}>
                {selfieUrl &&
                  <PhotoPreview
                    src={selfieUrl}
                    deletePhoto={deleteImage}
                    index={0}
                    fileName={getFileName(images[0].file, 'guest_selfie')}
                    setPreviewUrl={setSelfieUrl}
                  />
                }
              </Box>
            </InputGroup>
          </Box>
        </Box>
        <BottomButtonsWrapper justifyContent={{ xs: 'flex-end', md: 'center' }}>
          <Button
            href={previousStepUrl}
            size="large"
            sx={{ mr: { xs: 0.5, md: 1 } }}>
            {t('reservation_form.back')}
          </Button>
          <Button
            onClick={() => handleSubmitForm(nextStepUrl)}
            size="large"
            loading={loading}
            disabled={loading}
          >
            {t('reservation_form.next')}
          </Button>
        </BottomButtonsWrapper>
      </Container>
      <FinishLaterModal
        open={openFinishLater}
        close={setOpenFinishLater}
        submit={handleSubmitForm}
        step={2}
        path={nextStepUrl}
        hideAddress={simplifiedFormWithoutAddress}
      />
    </ThemeProvider >
  );
};

export default Nationality;
