import React, { useState } from 'react';
import { t } from '../../../../js/common/translations';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { Box, Button, Dialog, Drawer, Link, SvgIcon, Typography } from '../../elements';
import { CircularProgress, Step, StepLabel, Stepper, TopStepsWrapper } from './ReservationForm.styles';

const topSteps = [t('reservation_form.pre_payment'), t('reservation_form.guest_form'), t('reservation_form.contract')];

const steps = [
  { label: t('reservation_form.personal_info.title'), step: 1 },
  { label: t('reservation_form.nationality.title_short'), step: 2 },
  { label: t('reservation_form.address.title'), step: 3 },
  { label: t('reservation_form.additional_info.title'), step: 4 },
  { label: t('reservation_form.guests.title'), step: 5 },
  { label: t('reservation_form.complete'), step: 6 }
];

const availableSteps = (steps, withoutAddress) => {
  const allSteps = [...steps];

  if (withoutAddress) {
    allSteps.splice(2, 1);
  }

  allSteps.forEach((step, index) => {
    step.step = index + 1;
  });

  return allSteps;
};

export const FinishLaterModal = ({ open, close, submit, status, path, step, hideAddress }) => {
  const mobile = ['xs', 'sm'].includes(useBreakpoint());
  const [loading, setLoading] = useState(false);
  const baseUrl = path.split('?')[0];
  let url = `${baseUrl}`;
  if (hideAddress) {
    url = `${baseUrl}?guest=1&step=5`;
  } else {
    url = `${baseUrl}?step=6`;
  }

  const handleSubmit = () => {
    setLoading(true);
    if (status && path) {
      submit(status, path, true);
    } else {
      submit(url, step, true);
    }
  };

  return (
    mobile ? (
      <Drawer
        anchor="bottom"
        open={open}
      >
        <Box p={1.5}>
          <Box display="flex" flexDirection="column" alignItems="center" my={1.5}>
            <SvgIcon name="success_large" width={50} height={50} />
            <Typography sx={{ textAlign: 'center' }} fontSize='1.625rem' fontWeight={700} mt={1}>{t('reservation_form.finish_later_modal.are_you_sure')}</Typography>
            <Typography>{t('reservation_form.finish_later_modal.no_worries')}</Typography>
            <Typography sx={{ textAlign: 'center' }} >{t('reservation_form.finish_later_modal.finish_tabas_team')}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button variant="outline" onClick={() => close(false)}>
              <Typography>{t('reservation_form.finish_later_modal.stay_button')}</Typography>
            </Button>
            <Button onClick={() => submit(step)} loading={loading}
              disabled={loading}>
              <Typography>{t('reservation_form.finish_later_modal.leave_button')}</Typography>
            </Button>
          </Box>
        </Box>
      </Drawer>
    ) : (
      <Dialog open={open}>
        <Box px={1.5}>
          <Box display="flex" flexDirection="column" alignItems="center" my={1.5}>
            <SvgIcon name="success_large" width={75} height={75} />
            <Typography fontSize='1.625rem' fontWeight={700} mt={1}>{t('reservation_form.finish_later_modal.are_you_sure')}</Typography>
            <Typography>{t('reservation_form.finish_later_modal.no_worries')}</Typography>
            <Typography>{t('reservation_form.finish_later_modal.finish_tabas_team')}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button
              size="large"
              variant="outline"
              onClick={() => close(false)}
            >
              <Typography>{t('reservation_form.finish_later_modal.stay_button')}</Typography>
            </Button>
            <Button onClick={() => handleSubmit()} size="large" loading={loading}
              disabled={loading}>
              <Typography>{t('reservation_form.finish_later_modal.leave_button')}</Typography>
            </Button>
          </Box>
        </Box>
      </Dialog>
    )
  );
};

export const TopStepper = (success) => {
  return (
    <TopStepsWrapper>
      <Typography pb={1.5} fontWeight={700} fontSize='1.625rem'>{t('reservation_form.booking_progress')}</Typography>
      <Stepper activeStep={success.success ? 2 : 1}>
        {topSteps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </TopStepsWrapper>
  );
};

export const MobileStepper = ({ porcentageValue, actualStep, title, hideAddress = false }) => (
  <Box display="flex" alignItems="center">
    <Box position="relative" width="fit-content">
      <Box>
        <CircularProgress variant="determinate" value={100} sx={{ opacity: 0.3 }} />
      </Box>
      <Box sx={{ position: 'absolute' }} top={0} left={0}>
        <CircularProgress variant="determinate" value={porcentageValue} />
      </Box>
      <Box position='absolute' top="50%" left="50%" sx={{ transform: 'translate(-50%, -50%)' }}>
        <Typography whiteSpace='nowrap' fontSize={'0.9rem'} fontWeight={500}>{t('reservation_form.step_mobile_counter', { actual_step: actualStep, all_steps: availableSteps(steps, hideAddress).length })}</Typography>
      </Box>
    </Box>
    <Typography ml={1} variant='h5'>{title}</Typography>
  </Box>
);

export const DesktopStepper = ({ activeStep, path, hideAddress = false }) => {
  const allSteps = availableSteps(steps, hideAddress);
  return (
    <Stepper activeStep={activeStep}>
      {allSteps.map(({ label, step }) => {
        const baseUrl = path.split('?')[0];
        return (
          <Step key={label}>
            <Link sx={{ textDecoration: 'none', pointerEvents: step === 6 && 'none' }}
              href={
                step === allSteps.length - 1
                  ? `${baseUrl}?guest=1&step=${step}`
                  : `${baseUrl}?step=${step}`
              }
            >
              <StepLabel sx={{ cursor: step !== allSteps.length && 'pointer', '&.MuiStepLabel-root': { cursor: step !== allSteps.length && 'pointer' } }}>{label}</StepLabel>
            </Link>
          </Step>
        );
      })}
    </Stepper>
  );
};
